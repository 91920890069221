var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const UndrawDesigner = function UndrawDesigner(props) {
  return _react2.default.createElement(
    'svg',
    {
      className: props.class,
      id: '780c6f38-12e9-4526-8343-95ef18389740',
      dataname: 'Layer 1',
      xmlns: 'http://www.w3.org/2000/svg',
      xmlnsXlink: 'http://www.w3.org/1999/xlink',
      style: { height: props.height, width: '100%' },
      viewBox: '0 0 971.44 662',
    },
    _react2.default.createElement(
      'defs',
      null,
      _react2.default.createElement(
        'lineargradient',
        {
          id: 'e33f255a-5f0d-48f0-b4f6-4629f34a01b8',
          x1: '178.28',
          y1: '633.99',
          x2: '299.74',
          y2: '633.99',
          gradientUnits: 'userSpaceOnUse',
        },
        _react2.default.createElement('stop', {
          offset: '0',
          stopColor: 'gray',
          stopOpacity: '.25',
        }),
        _react2.default.createElement('stop', {
          offset: '.54',
          stopColor: 'gray',
          stopOpacity: '.12',
        }),
        _react2.default.createElement('stop', {
          offset: '1',
          stopColor: 'gray',
          stopOpacity: '.1',
        })
      ),
      _react2.default.createElement(
        'lineargradient',
        {
          id: '3dfe494e-3d34-4aa1-8861-9aac963443b7',
          x1: '979.45',
          y1: '738.59',
          x2: '979.45',
          y2: '646.78',
          xlinkHref: '#e33f255a-5f0d-48f0-b4f6-4629f34a01b8',
        },
        _react2.default.createElement(
          'lineargradient',
          {
            id: '47059453-8610-4293-a227-11546ac98e4d',
            x1: '600.65',
            y1: '650.11',
            x2: '600.65',
            y2: '119',
            xlinkHref: '#e33f255a-5f0d-48f0-b4f6-4629f34a01b8',
          },
          _react2.default.createElement('lineargradient', {
            id: 'c720a710-d9a1-4694-b415-864894a8ed00',
            x1: '485.72',
            y1: '615.6',
            x2: '485.72',
            y2: '377.7',
            xlinkHref: '#e33f255a-5f0d-48f0-b4f6-4629f34a01b8',
          })
        )
      )
    ),
    _react2.default.createElement('path', {
      d: 'M299,604.11a23.58,23.58,0,0,0-9.35-25c-2.06-1.39-4.36-2.45-6.26-4.05-3.17-2.67-5-6.6-6.28-10.53-3.14-9.42-4.13-20-9.63-28.51-5-7.75-14.76-10-23.41-10.79-3.15-.28-6.46-.43-9.34.88-4.82,2.18-7.12,7.87-7.34,13.16s1.16,10.5,1.5,15.78-.6,11.09-4.49,14.67-9.47,4-14.64,4.72a96.94,96.94,0,0,0-19.84,4.93c-5.39,2-11.4,5.51-11.65,11.23-.21,4.68,3.57,8.42,6.41,12.15,5.31,7,8,16.16,6.16,24.73s-8,16.21-9.07,25.05a10.88,10.88,0,0,0,.89,6.62A12.11,12.11,0,0,0,186,662.7c3.33,2.57,7,4.64,10.2,7.43,6.57,5.82,10.13,14.27,12.87,22.61.26.8.5,1.62.75,2.42h-6.46A24,24,0,0,0,179.49,719h0a24,24,0,0,0,23.89,23.89h64.5A24,24,0,0,0,291.77,719h0a24,24,0,0,0-23.89-23.89H250.2c4.18-5.6,8.55-10.86,15-13s14.26-.89,19.32-5.39a15.15,15.15,0,0,0,4.39-8.69,25.87,25.87,0,0,0-2.83-17.18c-2-3.63-5-6.92-5.65-11-.55-3.51.72-7.07,2.44-10.19C287.75,620.79,296.38,613.9,299,604.11Z',
      transform: 'translate(-114.28 -119)',
      fill: 'url(#e33f255a-5f0d-48f0-b4f6-4629f34a01b8)',
      opacity: '.7',
    }),
    _react2.default.createElement('path', {
      d: 'M243.84,530.42c-3-.27-6.15-.41-8.89.84-4.59,2.08-6.78,7.5-7,12.53s1.11,10,1.43,15-.57,10.56-4.28,14-9,3.81-13.94,4.49A92.32,92.32,0,0,0,192.27,582c-5.13,1.87-10.85,5.24-11.09,10.7-.2,4.46,3.4,8,6.11,11.57,5.06,6.64,7.58,15.39,5.86,23.55s-7.58,15.44-8.64,23.86a10.36,10.36,0,0,0,.85,6.3,11.53,11.53,0,0,0,3.19,3.38c3.17,2.45,6.71,4.42,9.71,7.08,6.26,5.54,9.64,13.59,12.26,21.53a174.35,174.35,0,0,1,6,23.87c5.39-3.47,12.36-3.42,18.11-6.24,12.25-6,16.37-23.4,29.3-27.73,6.12-2,13.58-.85,18.4-5.13a14.42,14.42,0,0,0,4.18-8.27,24.64,24.64,0,0,0-2.7-16.36c-1.93-3.46-4.77-6.59-5.38-10.51-.53-3.35.69-6.73,2.32-9.7,4.65-8.45,12.87-15,15.38-24.33a22.46,22.46,0,0,0-8.9-23.81,66.35,66.35,0,0,1-6-3.86c-3-2.54-4.73-6.29-6-10-3-9-3.93-19-9.17-27.15C261.36,533.31,252.08,531.16,243.84,530.42Z',
      transform: 'translate(-114.28 -119)',
      fill: '#4db6ac',
    }),
    _react2.default.createElement('path', {
      d: 'M243.75,530.73s11.37,152.41-18.2,178.57',
      transform: 'translate(-114.28 -119)',
      fill: 'none',
      stroke: '#535461',
      strokeMiterlimit: '10',
      opacity: '.6',
    }),
    _react2.default.createElement('rect', {
      x: '68.05',
      y: '573.24',
      width: '106.92',
      height: '45.5',
      rx: '15.2',
      ry: '15.2',
      fill: props.primaryColor,
    }),
    _react2.default.createElement('path', {
      d: 'M181.2,589.87s29.08-3.39,64.59,18.21',
      transform: 'translate(-114.28 -119)',
      fill: 'none',
      stroke: '#535461',
      strokeMiterlimit: '10',
      opacity: '.6',
    }),
    _react2.default.createElement('path', {
      d: 'M245.22,626.27s16.16-26.73,50.86-31.84',
      transform: 'translate(-114.28 -119)',
      fill: 'none',
      stroke: '#535461',
      strokeMiterlimit: '10',
      opacity: '.6',
    }),
    _react2.default.createElement('path', {
      d: 'M184,651.86s40.31-6.83,58.83,4.55',
      transform: 'translate(-114.28 -119)',
      fill: 'none',
      stroke: '#535461',
      strokeMiterlimit: '10',
      opacity: '.6',
    }),
    _react2.default.createElement('path', {
      d: 'M240.57,672s21.1-9.57,44.84-1.8',
      transform: 'translate(-114.28 -119)',
      fill: 'none',
      stroke: '#535461',
      strokeMiterlimit: '10',
      opacity: '.6',
    }),
    _react2.default.createElement('path', {
      d: 'M1009.77,663.18V646.78h-82v75.82a16,16,0,0,0,16,16h50a16,16,0,0,0,16-16V705.8a21.31,21.31,0,1,0,0-42.62Zm0,34.43V671.38a13.12,13.12,0,1,1,0,26.23Z',
      transform: 'translate(-114.28 -119)',
      fill: 'url(#3dfe494e-3d34-4aa1-8861-9aac963443b7)',
      opacity: '.7',
    }),
    _react2.default.createElement('path', {
      d: 'M1007.14,665.74a19.46,19.46,0,1,0,19.46,19.46A19.46,19.46,0,0,0,1007.14,665.74Zm0,31.43a12,12,0,1,1,12-12A12,12,0,0,1,1007.14,697.18Z',
      transform: 'translate(-114.28 -119)',
      fill: props.primaryColor,
    }),
    _react2.default.createElement('path', {
      d: 'M1007.14,665.74a19.46,19.46,0,1,0,19.46,19.46A19.46,19.46,0,0,0,1007.14,665.74Zm0,31.43a12,12,0,1,1,12-12A12,12,0,0,1,1007.14,697.18Z',
      transform: 'translate(-114.28 -119)',
      opacity: '.1',
    }),
    _react2.default.createElement('path', {
      d: 'M818,531.78h74.84a0,0,0,0,1,0,0v74.07a9.75,9.75,0,0,1-9.75,9.75H827.77a9.75,9.75,0,0,1-9.75-9.75V531.78A0,0,0,0,1,818,531.78Z',
      fill: props.primaryColor,
    }),
    _react2.default.createElement('rect', {
      y: '615.6',
      width: '971.44',
      height: '32.93',
      fill: '#e0e0e0',
    }),
    _react2.default.createElement('path', {
      d: 'M753.25,514.26c-10.41-37.79-69.44-65.39-113.85-71.51,0-.28,0-.56,0-.84V409.57l.14,0V408.3a110.94,110.94,0,0,0,67-70.23,119.88,119.88,0,0,0,7.15-15.42c4.74-1.73,8.32-9.54,8.32-18.94a33.07,33.07,0,0,0-1.34-9.5,121.53,121.53,0,0,0,.89-14.42,120.18,120.18,0,0,0-39.7-89.38,23.45,23.45,0,0,0,.38-5.88c-1-15.53-17.59-26.08-33.13-26.78a32.8,32.8,0,0,0,5.53-34.64c-9.91,14.44-30.26,16-47.77,16.09,7.56-2.93,11-13.4,6.56-20.21-18.3,24.53-54,29.08-75.76,50.59a63.86,63.86,0,0,0-11.78,16.07,120.24,120.24,0,0,0-45.32,94.13,121.56,121.56,0,0,0,.87,14.29,33,33,0,0,0-1.38,9.63c0,9.44,3.61,17.28,8.39,19a119.88,119.88,0,0,0,7.18,15.47,110.92,110.92,0,0,0,66.54,70v33.8c0,.26,0,.52,0,.78-44.58,6-104.08,33.83-114.4,71.92-8.91,32.9-18.42,69-23.16,90.24,40.57,28.51,125.69,45.24,179,45.24,50.69,0,133.53-15.11,172.91-41.06C772.36,585.73,762.55,548,753.25,514.26Z',
      transform: 'translate(-114.28 -119)',
      fill: 'url(#47059453-8610-4293-a227-11546ac98e4d)',
      opacity: '.7',
    }),
    _react2.default.createElement('path', {
      d: 'M603.61,640.72c48.9,0,128.81-14.57,166.8-39.61-4.11-22.48-13.58-58.87-22.55-91.43-11.42-41.47-83.53-70.22-126.54-70.22H579.87c-43.15,0-115.35,28.91-126.63,70.56-8.6,31.74-17.77,66.59-22.34,87.05C470,624.58,552.15,640.72,603.61,640.72Z',
      transform: 'translate(-114.28 -119)',
      fill: props.primaryColor,
    }),
    _react2.default.createElement('circle', {
      cx: '486.81',
      cy: '164.5',
      r: '116.24',
      fill: props.hairColor,
    }),
    _react2.default.createElement('path', {
      d: 'M449.29,261.31h74.48a0,0,0,0,1,0,0v59.58a37.24,37.24,0,0,1-37.24,37.24h0a37.24,37.24,0,0,1-37.24-37.24V261.31A0,0,0,0,1,449.29,261.31Z',
      opacity: '.1',
    }),
    _react2.default.createElement('path', {
      d: 'M460,258.55h53.11a10.68,10.68,0,0,1,10.68,10.68v48.9a37.24,37.24,0,0,1-37.24,37.24h0a37.24,37.24,0,0,1-37.24-37.24v-48.9A10.68,10.68,0,0,1,460,258.55Z',
      fill: props.skinColor,
    }),
    _react2.default.createElement('path', {
      d: 'M563.71,408.43a107.19,107.19,0,0,0,74.48.21v-9.15H563.71Z',
      transform: 'translate(-114.28 -119)',
      opacity: '.1',
    }),
    _react2.default.createElement('circle', {
      cx: '486.81',
      cy: '187.67',
      r: '106.75',
      fill: props.skinColor,
    }),
    _react2.default.createElement('path', {
      d: 'M525,209.56s45,91.32,171.9,38.27L667.4,201.51,615,182.71Z',
      transform: 'translate(-114.28 -119)',
      opacity: '.1',
    }),
    _react2.default.createElement('path', {
      d: 'M525,208.22s45,91.32,171.9,38.27L667.4,200.16,615,181.36Z',
      transform: 'translate(-114.28 -119)',
      fill: props.hairColor,
    }),
    _react2.default.createElement('path', {
      d: 'M524.65,202.05a61.67,61.67,0,0,1,15.29-24.86c21-20.76,55.42-25.14,73.08-48.81,4.24,6.57,1,16.67-6.33,19.49,16.89-.11,36.52-1.59,46.08-15.52a31.64,31.64,0,0,1-5.34,33.41c15,.68,31,10.86,32,25.83.65,10-5.61,19.43-13.81,25.15s-18.12,8.35-27.89,10.5C609.19,233.54,506,259.88,524.65,202.05Z',
      transform: 'translate(-114.28 -119)',
      fill: props.hairColor,
    }),
    _react2.default.createElement('path', {
      d: 'M707.84,307.6H705l-55.06,49c-53-24.85-92,0-92,0l-57.75-47-5.82.73a106.75,106.75,0,0,0,213.46-2.75ZM600.92,375.42c-20.77,0-37.6-4.21-37.6-9.4s16.84-9.4,37.6-9.4,37.6,4.21,37.6,9.4S621.69,375.42,600.92,375.42Z',
      transform: 'translate(-114.28 -119)',
      fill: props.hairColor,
    }),
    _react2.default.createElement('ellipse', {
      cx: '380.01',
      cy: '187.57',
      rx: '9.93',
      ry: '18.62',
      fill: props.skinColor,
    }),
    _react2.default.createElement('ellipse', {
      cx: '593.55',
      cy: '187.57',
      rx: '9.93',
      ry: '18.62',
      fill: props.skinColor,
    }),
    _react2.default.createElement('rect', {
      x: '279.16',
      y: '377.7',
      width: '413.12',
      height: '237.89',
      rx: '14.5',
      ry: '14.5',
      fill: 'url(#c720a710-d9a1-4694-b415-864894a8ed00)',
      opacity: '.7',
    }),
    _react2.default.createElement('rect', {
      x: '286.64',
      y: '382.09',
      width: '398.16',
      height: '233.5',
      rx: '26.84',
      ry: '26.84',
      fill: '#535461',
    }),
    _react2.default.createElement('circle', {
      cx: '485.72',
      cy: '498.85',
      r: '13.47',
      fill: '#fff',
    }),
    _react2.default.createElement('rect', {
      x: '41.16',
      y: '648.53',
      width: '889.11',
      height: '13.47',
      fill: '#e0e0e0',
    }),
    _react2.default.createElement('rect', {
      x: '41.16',
      y: '648.53',
      width: '889.11',
      height: '4.49',
      opacity: '.1',
    }),
    _react2.default.createElement('path', {
      d: 'M993.67,650.78V720a14.59,14.59,0,0,1-14.59,14.59h7.48A14.59,14.59,0,0,0,1001.15,720V650.78Z',
      transform: 'translate(-114.28 -119)',
      opacity: '.1',
    }),
    _react2.default.createElement('path', {
      d: 'M947.06,595c-1.26.22-2.69.56-3.31,1.68-1,1.78.8,3.79,2.42,5,3,2.26,6.16,4.48,8.2,7.63s2.68,7.55.45,10.58c-2.91,3.95-9.85,4.79-10.52,9.65-.43,3.12,2.26,5.78,5,7.34,6.74,3.86,15.43,4.89,20.32,10.93,2-4.15,6.54-6.55,11-7.58s9.18-.95,13.75-1.64c1.47-.22,3.13-.67,3.79-2,1.28-2.59-2.36-5.7-1.11-8.3,1-2,3.76-1.83,5.81-2.62,3.34-1.29,4.71-5.59,3.77-9s-3.62-6.15-6.44-8.36c-7.58-6-16.7-9.46-25.93-12-4.29-1.17-9.33-3-13.78-3.19C956.23,592.91,951.32,594.25,947.06,595Z',
      transform: 'translate(-114.28 -119)',
      fill: '#eee',
    })
  );
};

UndrawDesigner.propTypes = {
  /**
   * Hex color
   */
  skinColor: _propTypes2.default.string,
  /**
   * Hex color
   */
  hairColor: _propTypes2.default.string,
  /**
   * Hex color
   */
  primaryColor: _propTypes2.default.string,
  /**
   * Hex color
   */
  accentColor: _propTypes2.default.string,
  /**
   * percentage
   */
  height: _propTypes2.default.string,
  /**
   * custom class for svg
   */
  class: _propTypes2.default.string,
};

UndrawDesigner.defaultProps = {
  skinColor: '#e2bd95',
  primaryColor: '#6c68fb',
  hairColor: '#222',
  accentColor: '#43d1a0',
  height: '100%',
  class: '',
};

export default UndrawDesigner;
