import React from 'react';
import './SocialMedia.css';
import { socialMediaLinks } from '../../website-data';

const SocialMedia = () => {
  return (
    <div className='social-media-div'>
      {socialMediaLinks.github ? (
        <a
          href={socialMediaLinks.github}
          className='icon-button github'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-github'></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.linkedin ? (
        <a
          href={socialMediaLinks.linkedin}
          className='icon-button linkedin'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-linkedin-in'></i>
          <span></span>
        </a>
      ) : null}

      {socialMediaLinks.gmail ? (
        <a
          href={`mailto:${socialMediaLinks.gmail}`}
          className='icon-button google'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-google'></i>
          <span></span>
        </a>
      ) : null}
    </div>
  );
};

export default SocialMedia;
